#header div {
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
  margin: 15px;
  font-family: "DoHyeon";
}

.containerFill {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 10px;
  font-family: "DoHyeon";
}
