@font-face {
  font-family: "DoHyeon";
  src: url("./fonts/DoHyeon-Regular.ttf");
}
@font-face {
  font-family: "NotoSansKR-Bold";
  src: url("./fonts/NotoSansKR-Bold.otf");
}
@font-face {
  font-family: "NotoSansKR-Regular";
  src: url("./fonts/NotoSansKR-Regular.otf");
}
