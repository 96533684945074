.container {
  display: flex;
  position: relative;
  height: 30vh;
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
  background-color: #ececec;
}
.container > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.desc {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px 15px 25px 15px;
  border-radius: 0 0 15px 15px;
  color: white;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
}
