.toolList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(130px, auto);
  gap: 15px;
}

/* grid cell */
.toolList a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: black;
  background-color: #ececec;
  text-decoration: none;
  font-weight: bold;
  box-shadow: 0px 3px 3px -2px rgb(145 158 171 / 20%), 0px 3px 4px 0px rgb(145 158 171 / 14%), 0px 1px 8px 0px rgb(145 158 171 / 12%);
}

.toolList a svg {
  font-size: 40px;
}

.toolList a p {
  padding-top: 13px;
  font-size: 1.1em;
  margin: 0;
}
